<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between g-3">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Empleados / <strong class="text-primary small">Nuevo Empleado</strong></h3>
                </div>
                <div class="nk-block-head-content">
                  <router-link to="/sistema/empleados" replace class="btn btn-outline-light bg-white d-none d-sm-inline-flex"><em class="icon ni ni-arrow-left"></em><span>Regresar</span></router-link>
                  <router-link to="/sistema/empleados" replace class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"><em class="icon ni ni-arrow-left"></em></router-link>
                </div>
              </div>
            </div><!-- .nk-block-head -->
            <div class="nk-block">
              <div class="card card-bordered">
                <div class="card-aside-wrap">
                  <div class="card-content">
                    <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                      <li class="nav-item">
                        <a class="nav-link" href="#" :class="{active: section===''}" @click.prevent="showSection('')"><em class="icon ni ni-user-circle"></em><span>Información Personal</span></a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" href="#"><em class="icon ni ni-alert"></em><span>Las demás secciones estarán disponibles al guardar al empleado</span></a>
                      </li>
                    </ul><!-- .nav-tabs -->
                    <form v-if="section === ''" @submit.prevent="save">
                      <div class="card-inner">
                        <div class="nk-block">
                          <div class="nk-block-head">
                            <h5 class="title">Información Personal</h5>
                            <p>Datos básicos como nombre, correo electrónico, etc.</p>
                          </div><!-- .nk-block-head -->
                          <div class="profile-ud-list">
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Nombre <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <input type="text" class="form-control" v-model="model.nombre" required>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Apellidos <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <input type="text" class="form-control" v-model="model.apellidos" required>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Teléfono</span>
                                <div class="profile-ud-value">
                                  <input ref="input_tel" type="tel" class="form-control" v-model="model.telefono">
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Género <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <select class="form-control" v-model="model.genero">
                                    <option v-for="opt in ['Masculino','Femenino','Otro']" :key="opt" :value="opt">{{opt}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Estado Civil <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <select class="form-control" v-model="model.estado_civil">
                                    <option v-for="opt in ['Soltero','Casado','Divorciado', 'Viudo', 'Otro']" :key="opt" :value="opt">{{opt}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label"><span>Fecha de Nacimiento <span class="ml-1 text-danger">*</span></span></span>
                                <div class="profile-ud-value">
                                  <input type="date" class="form-control" v-model="model.fecha_nacimiento" required>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Estado <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <SelectEstado v-model:estado="model.id_estado"></SelectEstado>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Municipio <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <SelectMunicipio :estado="model.id_estado" v-model:municipio="model.id_municipio"></SelectMunicipio>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Servicio <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <SelectServicio class="form-control" v-model:servicio="model.id_servicio"></SelectServicio>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Puesto <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <input type="text" class="form-control" v-model="model.puesto" maxlength="500" required>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Fecha de Ingreso <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <input type="date" class="form-control" v-model="model.fecha_ingreso" required>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Superior <span class="ml-1 text-danger">*</span></span>
                                <span class="profile-ud-value">
                                  <SelectSuperior v-model:superior="model.id_superior"></SelectSuperior>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Contacto de Emergencia</span>
                                <div class="profile-ud-value">
                                  <textarea v-model="model.contacto_emergencia" class="form-control" maxlength="500"></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Observaciones</span>
                                <div class="profile-ud-value">
                                  <textarea v-model="model.observaciones" class="form-control" maxlength="5000"></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item" v-if="isAdmin">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Observaciones Internas</span>
                                <div class="profile-ud-value">
                                  <textarea v-model="model.observaciones_internas" class="form-control" maxlength="5000"></textarea>
                                </div>
                              </div>
                            </div>
                          </div><!-- .profile-ud-list -->
                          <div class="w-100 text-right">
                            <button type="submit" class="mt-2 btn btn-lg btn-primary">Guardar Empleado</button>
                          </div>
                        </div><!-- .nk-block -->
                      </div><!-- .card-inner -->
                    </form>
                  </div><!-- .card-content -->
                </div><!-- .card-aside-wrap -->
              </div><!-- .card -->
            </div><!-- .nk-block -->
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
  </AppView>
</template>

<script>
import AppView from "@/components/sistema/AppView";
import {useApi} from "@/use/useApi";
import {useRouter} from "vue-router";
import { ref, defineComponent, onMounted } from 'vue';
import Swal from "sweetalert2";
import intlTelInput from "intl-tel-input";
import useAuth from "@/providers/auth";
import SelectEstado from "@/components/globales/SelectEstado";
import SelectMunicipio from "@/components/globales/SelectMunicipio";
import SelectServicio from "@/components/globales/SelectServicio";
import SelectSuperior from "@/components/globales/SelectSuperior";
export default defineComponent({
  name: "Nuevo Empleado",
  components: {SelectSuperior, SelectServicio, SelectMunicipio, SelectEstado, AppView},
  setup() {
    const { loading, make } = useApi();
    const { isAdmin } = useAuth();

    const router = useRouter();

    const model = ref({
      nombre: '',
      apellidos: '',
      telefono: '',
      genero: 'Masculino',
      estado_civil: 'Soltero',
      fecha_nacimiento: '',
      id_estado: '01',
      id_municipio: '001',
      id_servicio: '',
      puesto: '',
      fecha_ingreso: '',
      observaciones: '',
      observaciones_internas: '',
      id_superior: '',
      contacto_emergencia: ''
    });

    const input_tel = ref(null);
    const section = ref('');


    async function save(){
      loading.message = 'Guardando empleado';
      //Validar telefono
      if(window.iti.isValidNumber()){
        model.value.telefono = window.iti.getNumber();
      }
      let {data} = await make('empleados/new', {model: model.value});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          Swal.fire('Éxito', data.message, 'success').then(() => {
            router.push('/sistema/empleados/editar/'+data.id_empleado);
          });
        }
      }
    }

    function showSection(_section){
      section.value = _section;
    }

    //Mounted
    onMounted(() => {
      window.iti = intlTelInput(input_tel.value, {
        initialCountry: 'mx',
        onlyCountries: ['us','mx'],
        nationalMode: true,
        utilsScript: require('intl-tel-input/build/js/utils.js')
      });
    });

    return {model, input_tel, loading, section, isAdmin, save, showSection};
  }
});
</script>

<style scoped>
.profile-ud-list {
  max-width: inherit;
}
</style>